@font-face {
  font-family: 'Ubuntu';
  src: url('./fonts/ubuntu-r-webfont.woff2') format('woff2'),
    url('./fonts/ubuntu-r-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu Bold';
  src: url('./fonts/ubuntu-b-webfont.woff2') format('woff2'),
    url('./fonts/ubuntu-b-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue';
  src: url('./fonts/bebasneue-bold-webfont.woff2') format('woff2'),
    url('./fonts/bebasneue-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html {
  box-sizing: border-box;
  outline: none;
}

:root {
  font-size: 62.5%;
}

*,
*:after,
*:before {
  box-sizing: inherit;
}

html,
body {
  background: #E4E5E8;
  color: #353539;
  font-weight: normal;
  font-family: 'Ubuntu', 'HelveticaNeue-Regular', 'Helvetica Neue Regular', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  word-wrap: break-word;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
}

li, ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

/* Animations */

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
